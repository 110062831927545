import { Loader } from 'src/design-system'
import { ToastPosition, toast } from 'react-hot-toast'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { RequestResult } from '../api/types'

export const promiseToast = (
  id: string,
  promise: Promise<RequestResult>,
  success: React.ReactNode,
  error?: React.ReactNode,
  loading?: string,
  position?: ToastPosition
) =>
  toast.promise(
    promise,
    {
      error: (
        <span className="flex gap-1">
          {error || (
            <div>
              Something went wrong. Please try again or&nbsp;
              <a
                className="text-red-600 underline"
                href="mailto:support@progressionapp.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact support
              </a>
              .
            </div>
          )}
          <button
            aria-label="Close"
            className="close"
            onClick={() => toast.dismiss(id)}
          >
            <X className="h-4 text-red-600 w-4" weight="bold" />
          </button>
        </span>
      ),
      loading: (
        <span className="flex gap-1 items-center">
          <Loader className="h-4 w-4" />
          {loading || 'Loading..'}
        </span>
      ),
      success: (data) => {
        if (!data.success) throw new Error()

        return (
          <span className="flex gap-1">
            {success}
            <button
              aria-label="Close"
              className="close"
              onClick={() => toast.dismiss(id)}
            >
              <X className="h-4 text-white w-4" weight="bold" />
            </button>
          </span>
        )
      },
    },
    {
      error: {
        className: 'bg-red-100 text-red-600',
        icon: null,
      },
      id,
      loading: { icon: null },
      position: position || 'top-center',
      success: {
        className: 'bg-green-600 text-white text-center',
        icon: null,
      },
    }
  )
